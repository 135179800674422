<template>
	<CommonPage>
		<div class="detail">
			<div class="topbar fc">
				<div
					class="flex fr aic fillav"
					@click="showCate = !showCate">
					<div style="cursor: pointer">
						{{ chapterName }}
					</div>
					<svg
						@click="showCate = !showCate"
						v-show="!showCate"
						width="23"
						height="14"
						viewBox="0 0 23 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M11.0565 9.63929L2.4688 0.43943C1.96176 -0.102825 1.12609 -0.117411 0.60209 0.406847C0.0772614 0.931064 0.0621607 1.79618 0.568375 2.33839L10.0727 12.5196C10.579 13.061 11.4151 13.0756 11.9383 12.5521L21.7917 2.70885C22.0542 2.44592 22.1892 2.09862 22.1953 1.74903C22.2014 1.3994 22.0786 1.04761 21.8246 0.777288C21.3184 0.235047 20.484 0.220482 19.9591 0.744725L11.0565 9.63929Z"
							fill="white" />
					</svg>
					<svg
						@click="showCate = !showCate"
						v-show="showCate"
						width="23"
						height="14"
						viewBox="0 0 23 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M11.0565 3.48791L2.4688 12.6878C1.96176 13.23 1.12609 13.2446 0.60209 12.7204C0.0772614 12.1961 0.0621607 11.331 0.568375 10.7888L10.0727 0.607647C10.579 0.0662318 11.4151 0.0516378 11.9383 0.575085L21.7917 10.4183C22.0542 10.6813 22.1892 11.0286 22.1953 11.3782C22.2014 11.7278 22.0786 12.0796 21.8246 12.3499C21.3184 12.8921 20.484 12.9067 19.9591 12.3825L11.0565 3.48791Z"
							fill="white" />
					</svg>
				</div>
				<div
					v-show="deviceType === 'desktop'"
					class="pro"></div>
			</div>

			<div
				class="content"
				@click="clkcontent($event)">
				<div id="t0">{{ String(curCateNo).padStart(3, '0') }}surah</div>
				<Ad
					:ads="adsensConfig.ad5"
					ref="ad5"
					class="span2"></Ad>
				<div
					class="item"
					:id="`t${index + 1}`"
					v-for="(value, key, index) in tMap"
					:key="key">
					<div class="t1">{{ `${curCateNo}:${index + 1}` }}</div>
					<div class="t2">
						{{ value }}
					</div>
					<div
						class="t3"
						style="border-bottom: 1px solid #313335">
						{{ ttMap[`verse_${index + 1}`] }}
					</div>
				</div>

				<div class="btns flex aic">
					<div
						class="b1 flex aic"
						@click.stop="c1">
						Beginning of Surah
					</div>
					<div
						class="b2 flex aic"
						@click.stop="c2">
						Next Surah
						<svg
							width="16"
							height="26"
							viewBox="0 0 16 26"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="M11.379 13.0839L1.43938 3.47562C0.85354 2.90834 0.85354 1.99025 1.43938 1.42478C2.0252 0.858407 2.97565 0.858407 3.56146 1.42478L14.5613 12.0587C15.1462 12.6251 15.1462 13.5436 14.5613 14.1082L3.56146 24.7417C3.26765 25.0249 2.88363 25.1665 2.49955 25.1665C2.11544 25.1665 1.73141 25.0249 1.43938 24.7408C0.85354 24.1744 0.85354 23.2576 1.43938 22.6912L11.379 13.0839Z"
								fill="white" />
							<path
								d="M11.379 13.0839L1.43938 3.47562C0.85354 2.90834 0.85354 1.99025 1.43938 1.42478C2.0252 0.858407 2.97565 0.858407 3.56146 1.42478L14.5613 12.0587C15.1462 12.6251 15.1462 13.5436 14.5613 14.1082L3.56146 24.7417C3.26765 25.0249 2.88363 25.1665 2.49955 25.1665C2.11543 25.1665 1.73141 25.0249 1.43938 24.7408C0.85354 24.1744 0.85354 23.2576 1.43938 22.6912L11.379 13.0839"
								stroke="white" />
						</svg>
					</div>
				</div>
			</div>

			<div
				class="cate flex"
				v-show="showCate">
				<div class="left">
					<div
						class="text"
						@click="gotoChapter(item.transliteratedName)"
						v-for="item in cateArr"
						:key="item.transliteratedName"
						:class="{ activeC: item.transliteratedName === chapterName }">
						{{ item.transliteratedName }}
					</div>
				</div>
				<div class="right f1">
					<div
						:id="`n${item + 1}`"
						:class="{ activeC: item + 1 === curT }"
						@click="gotoT(item + 1)"
						class="num"
						v-for="item in [...Array(curCate.versesCount).keys()]"
						:key="item">
						{{ item + 1 }}
					</div>
				</div>
			</div>
		</div>
		<Ad
			:ads="adsensConfig.ad6"
			ref="ad6"
			class="span2"></Ad>
	</CommonPage>
</template>

<script>
import chaptersArr from '../quran/chapters.js'
const title = 'Quran Growth - Surah Details and Verses'
const description = 'Dive into the details of each Surah in the Quran. Read and understand the verses with our detailed Quranic insights.'
import '../css/detail.scss'
export default {
	name: 'detail',
	metaInfo: {
		title,
		meta: [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'title',
				content: title,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
		],
	},
	data() {
		return {
			showCate: false,
			cateArr: [],
			// 当前章节
			curCate: null,
			// 当前第几小节
			curT: 1,
			// 当前第几章
			curCateNo: undefined,
			// 当前章全部小节数组
			tMap: undefined,
			// 翻译数组
			ttMap: undefined,
			allT: 0,
			f: false,
		}
	},
	created() {
		let curCate = chaptersArr.find((e, i) => {
			if (e.transliteratedName === this.chapterName) {
				this.curCateNo = i + 1
				return true
			} else {
				return false
			}
		})
		if (!curCate) {
			this.$router.push({ name: '404' })
			return
		}
		this.curCate = curCate
		this.allT = curCate.versesCount
		this.cateArr = chaptersArr
	},
	beforeMount() {

	},
	deactivated() {
		this.showCate = false
	},
	activated() {
		document.querySelector('body').style.backgroundColor = '#131518'
	},
	watch: {
		showCate(newV, oldV) {
			if (newV) {
				setTimeout(() => {
					document.querySelector('.text.activeC').scrollIntoView({
						block: 'center',
					})
					document.querySelector('.num.activeC').scrollIntoView({
						block: 'center',
					})
				})
			}
		},
		curT(newVal, oldVal) {
			document.querySelector(`n${oldVal}`)?.classList.remove('activeC')
			document.querySelector(`n${newVal}`)?.classList.add('activeC')
		},
	},
	async mounted() {
		const o = require(`../quran/surah/surah_${this.curCateNo}.json`).verse
		if (o['verse_0']) {
			delete o.verse_0
		}
		this.tMap = o

		const t = require(`../quran/en/en_translation_${this.curCateNo}.json`).verse
		if (t['verse_0']) {
			delete t.verse_0
		}
		this.ttMap = t

		// await this.loadAdSenseScript()

		document.querySelector('#t3').after(this.$refs.ad6.$el)

		const textArrEl = document.querySelectorAll('.item')

		document.addEventListener(
			'scroll',
			this.debounce((e) => {
				let i = 1
				for (const el of textArrEl) {
					if (this.elementsOverlap(el, document.querySelector('.topbar'))) {
						this.curT = i + 1
						break
					}
					i++
				}
				if (Array.from(textArrEl).every((e) => !this.elementsOverlap(e, document.querySelector('.topbar')))) {
					this.curT = 1
				}

				if (this.elementsOverlap(textArrEl[textArrEl.length - 1], document.querySelector('.topbar'))) {
					this.curT = this.curCate.versesCount
				}

				const scrollY = window.scrollY || window.pageYOffset
				if (scrollY > 20) {
					cate.classList.add('moveCate')
				} else {
					cate.classList.remove('moveCate')
				}

				// 处理进度条逻辑
				var scrollTop = document.documentElement.scrollTop || document.body.scrollTop // 已经读过被卷起来的文档部分
				var scrollHeight = document.documentElement.scrollHeight - 300 // 文档总高度
				var clientHeight = document.documentElement.clientHeight // 窗口可视高度
				const n = +(scrollTop / (scrollHeight - clientHeight)).toFixed(2) * 100
				const w = n + '%'
				document.querySelector('.pro').style.width = w
			}, 100),
		)

		// 处理移动端固定顶部
		const cate = document.querySelector('.cate')
		const topbar = document.querySelector('.topbar')
		if (this.deviceType === 'mobile') {
			document.onscroll = () => {
				const scrollY = window.scrollY || window.pageYOffset
				if (scrollY > 20) {
					cate.classList.add('moveCate')
					topbar.classList.add('setFix')
				} else {
					cate.classList.remove('moveCate')
					topbar.classList.remove('setFix')
				}
			}
			return
		}
	},
	methods: {
		//广告相关start
		async loadAdSenseScript() {
			// 其他需要在初次加载时执行的操作
			// 先检查广告是否已经加载
			if (window.adsbygoogle && window.adsbygoogle.loaded) {
				// 检查广告是否已经加载
				console.log('Adsense script already loaded.')
				this.$eventrack('adscript_loaded', 'expose')
				this.loadAdWithDelay()
				return // 如果已加载，直接返回
			}
			await this.loadScriptConditionally()
		},
		loadScriptConditionally() {
			// console.log(this.adsensConfig)
			// 判断广告脚本URL是否存在
			if (!this.adsensConfig?.scriptUrl) {
				console.log('广告脚本的URL不存在,终止加载广告外链')
				this.$eventrack('no_adscript_config', 'expose')
				return
			}
			// 检查该脚本是否已经被添加
			const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`)
			if (existingScript) {
				this.$eventrack('adscript_exist', 'expose')
				console.log('脚本已存在，无需重新添加')
				return
			}

			console.log('准备插入脚本')
			const script = document.createElement('script')
			script.src = this.adsensConfig?.scriptUrl
			script.crossOrigin = 'anonymous'
			script.async = true

			const header = document.getElementsByTagName('head')[0]
			header.appendChild(script)

			this.$eventrack('adscript_add_success', 'expose')
			script.onload = this.loadAdWithDelay.bind(this) // 使用 bind 确保 this 指向正确
			console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作')
		},
		loadAdWithDelay() {
			setTimeout(() => {
				this.displayAd()
			}, 500)
		},
		async displayAd() {
			await this.$nextTick()

			// 获取所有 ads 元素的 refs，过滤掉非广告的 ref
			const adsElements = Object.entries(this.$refs)
				.filter(([key]) => key.startsWith('ad')) // 只选择以 'ads-' 开头的 refs
				.flatMap(([, ref]) => ref) // 展开并获取所有元素

			if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
				console.log('Adsense script not loaded yet, delaying ad display.')
				setTimeout(this.displayAd, 500) // 延迟再次尝试
				return
			}

			console.log(adsElements) // 检查是否包含 <ins> 标签
			adsElements.forEach((ad) => {
				console.log(ad) // 输出每个广告元素
				console.log('ready to push')
				;(window.adsbygoogle = window.adsbygoogle || []).push({})
			})
		},
		gotoChapter(c) {
			location.href = '/' + c
		},
		elementsOverlap(el1, el2) {
			const domRect1 = el1.getBoundingClientRect()
			const domRect2 = el2.getBoundingClientRect()

			return !(domRect1.top > domRect2.bottom || domRect1.right < domRect2.left || domRect1.bottom < domRect2.top || domRect1.left > domRect2.right)
		},
		clkcontent(e) {
			if (this.showCate) this.showCate = false

			if (this.deviceType === 'mobile') {
				if (e.pageX < window.innerWidth / 2) {
					// 跳转到前一小节
					if (this.curCateNo === 1) {
						return
					}
					console.log(this.curCateNo)
					console.log(`/${this.cateArr[this.curCateNo - 2].transliteratedName}`)
					location.href = `/${this.cateArr[this.curCateNo - 2].transliteratedName}`
				} else {
					// 跳转到后一小节
					if (this.curCateNo === 114) {
						return
					}
					location.href = `/${this.cateArr[this.curCateNo].transliteratedName}`
				}
			}
		},
		debounce(func, delay, immediate) {
			let timer
			return function () {
				if (timer) clearTimeout(timer)
				if (immediate) {
					// 复杂的防抖函数
					// 判断定时器是否为空，如果为空，则会直接执行回调函数
					let firstRun = !timer
					// 不管定时器是否为空，都会重新开启一个新的定时器,不断输入，不断开启新的定时器，当不在输入的delay后，再次输入就会立即执行回调函数
					timer = setTimeout(() => {
						timer = null
					}, delay)
					if (firstRun) {
						func.apply(this, arguments)
					}
					// 简单的防抖函数
				} else {
					timer = setTimeout(() => {
						func.apply(this, arguments)
					}, delay)
				}
			}
		},
		gotoT(t) {
			this.curT = t
			window.scrollBy(0, document.querySelector(`#t${t - 1}`).getBoundingClientRect().top + 30)
			this.showCate = false
		},

		c1() {
			document.querySelector('#t1').scrollIntoView({
				block: 'center',
				behavior: 'instant',
			})
		},
		c2() {
			if (this.curCateNo === 114) {
				return
			}
			location.href = `/${this.cateArr[this.curCateNo].transliteratedName}`
		},
	},
	computed: {
		chapterName() {
			return this.$route.params.chapter
		},
	},
}
</script>
